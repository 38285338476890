<aside [ngClass]="isCollapsed ? 'small hide' : 'hide'">
  <div class="logo">
    <div class="logo" *ngIf="!isCollapsed">
      <img
        src="../../../assets/img/atps_web.png"
        height="65"
        width="180"
        alt=""
      />
    </div>

    <div class="logo" *ngIf="isCollapsed">
      <img src="../../../assets/img/A..png" height="20" width="20" alt="" />
    </div>
  </div>

  <div [ngClass]="isCollapsed ? 'menu menu-small' : 'menu'">
    <ul>
      <ng-container *ngFor="let link of links; let index = index">
        <!--  <li>
          <div [ngClass]="'side-link'">
            <ng-container>
              <a
                class="side-link"
                [routerLink]="link.path"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i nz-icon [nzType]="link.icon" nzTheme="outline"></i>
                {{ link.title }}
              </a>
            </ng-container>
          </div>
        </li> -->

        <li>
          <div [ngClass]="link.isOpen ? 'side-link active' : 'side-link'">
            <ng-container>
              <a
                class="side-link"
                [routerLink]="link.path"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="toggleDropdown(index)"
              >
                <i nz-icon [nzType]="link.icon" nzTheme="outline"></i>
                <div
                  class="d-flex justify-content-between w-100 ms-2"
                  *ngIf="!isCollapsed"
                >
                  <span>
                    {{ link.title }}
                  </span>
                  <span *ngIf="link.children" class="caret">
                    <i
                      nz-icon
                      [nzType]="link.isOpen ? 'caret-down' : 'caret-up'"
                      nzTheme="outline"
                    ></i>
                  </span>
                </div>
              </a>

              <ul class="active" *ngIf="link.children && link.isOpen">
                <ng-container *ngFor="let subLink of link?.children">
                  <li *ngIf="!subLink?.hide">
                    <a
                      href=""
                      [routerLink]="subLink.path"
                      routerLinkActive="active"
                      class="sub-link"
                    >
                      <i nz-icon [nzType]="subLink.icon" nzTheme="outline"></i>
                      <span class="ms-3" *ngIf="!isCollapsed"
                        >{{ subLink.title }}
                      </span>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </div>
        </li>
      </ng-container>
      <!--<li>
        <div class="side-link">
          <a
            href=""
            class="side-link"
            routerLink="/settings"
            routerLinkActive="active"
          >
            <i class="ci-settings"></i>

            <span *ngIf="!getIsCollapsed()" class="ms-2"> Paramétre</span>
          </a>
        </div>
      </li>-->
    </ul>
  </div>
  <div [class]="!isCollapsed ? 'side-bottom' : 'side-bottom sm'">
    <a class="link" (click)="handleLogout()">
      <i nz-icon nzType="logout" nzTheme="outline"></i>
      <span *ngIf="!isCollapsed"> Déconnexion</span>
    </a>
  </div>
</aside>

<nz-drawer
  [nzClosable]="false"
  [nzVisible]="visible"
  [nzPlacement]="'left'"
  [nzFooter]=""
  [nzWidth]="320"
  (nzOnClose)="close()"
  nzWrapClassName="sidebar"
>
  <ng-container *nzDrawerContent>
    <aside>
      <div class="logo">
        <div class="logo" *ngIf="!isCollapsed">
          <img
            src="../../../assets/img/atps_web.png"
            height="65"
            width="180"
            alt=""
          />
        </div>

        <div class="logo" *ngIf="isCollapsed">
          <img src="../../../assets/img/A..png" height="20" width="20" alt="" />
        </div>
      </div>

      <div [ngClass]="isCollapsed ? 'menu menu-small' : 'menu'">
        <ul>
          <ng-container *ngFor="let link of links; let index = index">
            <!--  <li>
              <div [ngClass]="'side-link'">
                <ng-container>
                  <a
                    class="side-link"
                    [routerLink]="link.path"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <i nz-icon [nzType]="link.icon" nzTheme="outline"></i>
                    {{ link.title }}
                  </a>
                </ng-container>
              </div>
            </li> -->

            <li>
              <div [ngClass]="link.isOpen ? 'side-link active' : 'side-link'">
                <ng-container>
                  <a
                    class="side-link"
                    [routerLink]="link.path"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="toggleDropdown(index)"
                  >
                    <i nz-icon [nzType]="link.icon" nzTheme="outline"></i>
                    <div
                      class="d-flex justify-content-between w-100 ms-2"
                      *ngIf="!isCollapsed"
                    >
                      <span>
                        {{ link.title }}
                      </span>
                      <span *ngIf="link.children" class="caret">
                        <i
                          nz-icon
                          [nzType]="link.isOpen ? 'caret-down' : 'caret-up'"
                          nzTheme="outline"
                        ></i>
                      </span>
                    </div>
                  </a>

                  <ul class="active" *ngIf="link.children && link.isOpen">
                    <ng-container *ngFor="let subLink of link?.children">
                      <li *ngIf="!subLink?.hide">
                        <a
                          href=""
                          [routerLink]="subLink.path"
                          (click)="openLink()"
                          routerLinkActive="active"
                          class="sub-link"
                        >
                          <i
                            nz-icon
                            [nzType]="subLink.icon"
                            nzTheme="outline"
                          ></i>
                          <span class="ms-3" *ngIf="!isCollapsed"
                            >{{ subLink.title }}
                          </span>
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                </ng-container>
              </div>
            </li>
          </ng-container>
          <!--<li>
            <div class="side-link">
              <a
                href=""
                class="side-link"
                routerLink="/settings"
                routerLinkActive="active"
              >
                <i class="ci-settings"></i>
    
                <span *ngIf="!getIsCollapsed()" class="ms-2"> Paramétre</span>
              </a>
            </div>
          </li>-->
        </ul>
      </div>
      <div [class]="!isCollapsed ? 'side-bottom' : 'side-bottom sm'">
        <a class="link" (click)="handleLogout()">
          <i nz-icon nzType="logout" nzTheme="outline"></i>
          <span *ngIf="!isCollapsed"> Déconnexion</span>
        </a>
      </div>
    </aside>
  </ng-container>
</nz-drawer>
