import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { API_URL } from 'src/config/url';
import { JwtHelperService } from '@auth0/angular-jwt';

import { BehaviorSubject, Observable } from 'rxjs';
import { SocketService } from '../socket.io/socket.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userInfo = new BehaviorSubject<any>(null);
  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private _socket: SocketService
  ) {}
  login(credentials: any) {
    return this.http.post<any>(`${API_URL}/auth/authenticate`, credentials);
  }

  storeToken(token: string) {
    localStorage.setItem('presence', token);
  }

  getSession() {
    return {
      token: localStorage.getItem('presence')?.toString(),
    };
  }
  getUserRoles(): any {
    const token = this.getSession()?.token;
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);

      let roles = [];

      roles = decodedToken.roles.map((role: any) => role?.authority);
      if (!decodedToken.roles || decodedToken.roles.length === 0) {
        roles = ['SUPER'];
      }

      return roles;
    }
  }

  isAuthorized(requiredRoles: string[]) {
    if (requiredRoles && requiredRoles.length > 0) {
      const userRoles = this.getUserRoles();
      if (userRoles) {
        const hasRequiredRole = requiredRoles.some((role) =>
          userRoles.includes(role)
        );
        if (hasRequiredRole) {
          return true;
        }
      }
    }

    return false;
  }

  isTokenExpired() {
    const { token } = this.getSession();
    if (token) {
      const expiry = JSON.parse(atob(token.split('.')[1])).exp;
      return expiry * 1000 <= Date.now();
    }
    return false;
  }

  isAuthenticate() {
    if (localStorage.getItem('presence')) {
      return true;
    } else {
      return false;
    }
  }

  me() {
    return this.http.get<any>(`${API_URL}/auth/me`);
  }

  getUserInfo(): Observable<any> {
    return this.userInfo.asObservable();
  }

  setUserInfo(user: any) {
    this.userInfo.next(user);
    this._socket
      .getSocket()
      .emit('connectUser', { userId: user.id, token: this.getSession().token });
  }

  logout(lastPath: string) {
    localStorage.clear();

    if (lastPath) {
      this.router.navigate(['/auth/signin'], {
        queryParams: { next: lastPath },
      });
      return;
    }
    this.router.navigate(['/auth/signin']);
  }
}
