import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public me: any;
  public isCollaped: boolean = false;
  public isAgency: boolean = false;
  public drawerMobile: boolean = false;
  constructor(
    private authService: AuthService,
    private layoutSerivce: LayoutService
  ) {
    this.isAgency = authService.isAuthorized([
      'ADMIN',
      'GESTIONNAIRE',
      'GESTIONNAIRE_STATION',
    ]);
  }

  ngOnInit(): void {
    this.layoutSerivce.isCollapsed.subscribe((val) => {
      this.isCollaped = val;
    });

    if (this.authService.isAuthenticate()) {
      this.authService.me().subscribe(
        (user) => {
          this.me = user;
          this.authService.setUserInfo(user);
        },
        () => {
          this.authService.logout('');
        }
      );
    }
  }
  logout() {
    this.authService.logout('');
  }

  avatar() {
    if (this.me) {
      const { firstName, lastName } = this.me;
      let name = `${firstName.charAt(0).toUpperCase()}+${lastName
        .charAt(0)
        .toUpperCase()}`;

      return `https://ui-avatars.com/api/?name=${name}&background=3366ff&color=fff`;
    }
    return 'https://ui-avatars.com/api/?background=3366ff&color=fff';
  }

  toggleSidebar() {
    const currentValue = this.layoutSerivce.$collapsed.getValue();
    this.layoutSerivce.setCollapsed(!currentValue);
  }

  toggleDrawer() {
    const currentValue = this.layoutSerivce.$showDrawerMobile.getValue();
    this.layoutSerivce.setDrawerMobile(!currentValue);
  }
}
