<nav class="header">
  <div class="left hide" (click)="toggleSidebar()" *ngIf="!isCollaped">
    <i nz-icon nzType="align-left" nzTheme="outline"></i> Réduire
  </div>

  <div class="left show" (click)="toggleDrawer()">
    <div class="logo">
      <img src="../../../assets/img/A..png" height="20" width="20" alt="" />
    </div>
    <i nz-icon nzType="align-left" nzTheme="outline"></i>
  </div>
  <div class="left" (click)="toggleSidebar()" *ngIf="isCollaped">
    <i nz-icon nzType="align-right" nzTheme="outline"></i> Agrandir
  </div>
  <div class="nav-actions">
    <a href="" class="link">
      <span nz-icon nzType="bell" nzTheme="outline"></span>
    </a>

    <div class="user-info-navbar" nz-dropdown [nzDropdownMenu]="menuUser">
      <img
        [src]="avatar()"
        width="40"
        height="40"
        alt="avatar user"
        loading="lazy"
      />
      <p class="hide">
        {{ me?.firstName + " " + me?.lastName }}
      </p>
    </div>
    <nz-dropdown-menu #menuUser="nzDropdownMenu">
      <ul nz-menu nzSelectable class="dropdown">
        <li nz-menu-item routerLink="profile">
          <i class="ci-user me-2"></i>
          Mon Profile
        </li>
        <li nz-menu-item routerLink="agency" *ngIf="isAgency">
          <i class="ci-user me-2"></i>
          Ma société
        </li>
        <li nz-menu-item routerLink="change-password">
          <i class="ci-edit me-2"></i>
          Changer mot de passe
        </li>
        <li nz-menu-item (click)="logout()">
          <i class="ci-exit me-2"></i>
          Déconnexion
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
</nav>
