import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
import { PermissionGuard } from './core/permission.guard';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dashbord/dashbord.module').then(
            (m) => m.DashbordModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/users/users.module').then((m) => m.UsersModule),
        canActivate: [PermissionGuard],
        data: {
          roles: ['SUPER', 'ADMIN', 'GESTIONNAIRE', 'GESTIONNAIRE_STATION'],
        },
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },

      {
        path: 'data',
        loadChildren: () =>
          import('./modules/exploitation-data/exploitation-data.module').then(
            (m) => m.ExploitationDataModule
          ),
        canActivate: [PermissionGuard],
        data: { roles: ['ADMIN', 'GESTIONNAIRE', 'GESTIONNAIRE_STATION'] },
      },
      {
        path: 'agencies',
        loadChildren: () =>
          import('./modules/agency/agency.module').then((m) => m.AgencyModule),
        canActivate: [PermissionGuard],
        data: { roles: ['SUPER'] },
      },
      {
        path: 'nfc-cards',
        loadChildren: () =>
          import('./modules/nfc-cards/nfc-cards.module').then(
            (m) => m.NfcCardsModule
          ),
      },

      {
        path: 'travel-history',
        loadChildren: () =>
          import('./modules/travel-history/travel-history.module').then(
            (m) => m.TravelHistoryModule
          ),
      },

      {
        path: 'real-time',
        loadChildren: () =>
          import('./modules/real-time/real-time.module').then(
            (m) => m.RealTimeModule
          ),
      },

      {
        path: 'passengers',
        loadChildren: () =>
          import('./modules/passenger/passenger.module').then(
            (m) => m.PassengerModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./modules/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
