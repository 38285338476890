import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public $collapsed = new BehaviorSubject<boolean>(false);
  public $showDrawerMobile = new BehaviorSubject<boolean>(false);
  constructor() {}

  get isCollapsed(): Observable<any> {
    return this.$collapsed.asObservable();
  }

  get drawerMobile(): Observable<any> {
    return this.$showDrawerMobile.asObservable();
  }

  setCollapsed(collapsed: boolean) {
    this.$collapsed.next(collapsed);
  }

  setDrawerMobile(showDrawerMobile: boolean) {
    this.$showDrawerMobile.next(showDrawerMobile);
  }
}
