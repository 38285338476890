import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';

import { RuntimeEnvironment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: any;

  constructor() {
    if (!this.socket) {
      this.socket = io(RuntimeEnvironment.SOCKET_BASE_URL, {
        transports: ['websocket'],
      });
    }
  }

  getSocket(): Socket {
    return this.socket;
  }

  onTrackingEvent() {
    let observable = new Observable<any>((observer) => {
      this.socket.on('onTracking', (data: any) => {
        console.log(data);
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
    return observable;
  }

  listenForTrackingEvent(callback: (data: any) => void) {
    this.socket.on('onTracking', callback);
  }
  io__onlineUsers(): any {
    this.socket.on('getUsers', (data: any) => {
      return data;
    });
  }
}
