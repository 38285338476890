import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  public visible: boolean = false;
  public links: any = [
    {
      title: 'Tableau de bord',
      path: '/',
      icon: 'dashboard',
    },
    {
      title: 'Gestion des sociétés',
      path: '/agencies',
      icon: 'bank',
      hide: !this.authService.isAuthorized(['SUPER']),
    },
    {
      title: 'Gestion des utilisateurs',
      path: '/users',
      icon: 'user',
      hide: !this.authService.isAuthorized(['ADMIN', 'SUPER']),
    },
    {
      title: 'Données d’exploitation',
      path: '/dashboard',
      icon: 'appstore',
      isOpen: false,
      hide: !this.authService.isAuthorized(['ADMIN', 'GESTIONNAIRE']),
      children: [
        {
          title: 'Gestion des lignes',
          path: '/data/routes',
          icon: 'swap',
        },
        {
          title: 'Gestion des stations',
          path: '/data/stops',
          icon: 'swap',
        },
        {
          title: 'Gestion des trajets',
          path: '/data/trips',
          icon: 'swap',
        },
        {
          title: 'Planification horaires',
          path: '/data/stop-times',
          icon: 'swap',
        },
        {
          title: 'Produits',
          path: '/data/products',
          icon: 'swap',
        },

        {
          title: 'Véhicules',
          path: '/data/vehicles',
          icon: 'swap',
        },
        {
          title: 'Règles tarifaires',
          path: '/data/fare-leg-rules',
          icon: 'swap',
        },
        {
          title: 'Calendriers',
          path: '/data/calendars',
          icon: 'swap',
        },
        {
          title: 'Les cadres temporels',
          path: '/data/time-frames',
          icon: 'swap',
        },
      ],
    },
    {
      title: 'Comptes voyageurs',
      path: '/passengers',
      icon: 'idcard',
    },
    {
      title: 'Gestion des cartes NFC',
      path: '/nfc-cards',
      icon: 'scan',
    },

    {
      title: 'Temps réel',
      path: '/real-time',
      icon: 'wifi',
    },

    {
      title: 'historique des voyages',
      path: '/travel-history',
      icon: 'history',
    },
    {
      title: 'Statistiques',
      path: '/stats',
      icon: 'pie-chart',
    },

    {
      title: 'Paramètrage',
      path: '/settings',
      icon: 'setting',
      isOpen: false,

      children: [
        {
          title: 'Regions',
          path: '/settings/regions',
          icon: 'global',
          hide: !this.authService.isAuthorized(['SUPER']),
        },
        {
          title: 'Réseaux',
          path: '/settings/networks',
          icon: 'swap',
          hide: !this.authService.isAuthorized(['ADMIN', 'GESTIONNAIRE']),
        },
        {
          title: 'Liste des zones',
          path: '/data/areas',
          icon: 'swap',
          hide: !this.authService.isAuthorized(['ADMIN', 'GESTIONNAIRE']),
        },
        {
          title: 'Type de voyageurs',
          path: '/settings/passenger-types',
          icon: 'one-to-one',
          hide: !this.authService.isAuthorized(['SUPER']),
        },

        {
          title: 'Mode ligne',
          path: '/settings/route-modes',
          icon: 'swap',
          hide: !this.authService.isAuthorized(['SUPER']),
        },

        {
          title: 'Prix des cartes',
          path: '/settings/card-prices',
          icon: 'swap',
          hide: !this.authService.isAuthorized(['ADMIN', 'GESTIONNAIRE']),
        },
        {
          title: 'Types de véhicules',
          path: '/settings/vehicle-types',
          icon: 'one-to-one',
          hide: !this.authService.isAuthorized(['ADMIN', 'GESTIONNAIRE']),
        },
      ],
    },
  ].filter((item) => !item.hide);
  public isCollapsed: boolean = false;
  constructor(
    private authService: AuthService,
    private layoutSerivce: LayoutService
  ) {}

  ngOnInit() {
    this.layoutSerivce.drawerMobile.subscribe(
      (visible) => (this.visible = visible)
    );
    this.layoutSerivce.isCollapsed.subscribe(
      (isCollapsed) => (this.isCollapsed = isCollapsed)
    );
  }

  openLink() {
    this.layoutSerivce.setDrawerMobile(false);
  }

  handleLogout() {
    this.authService.logout('');
  }

  toggleDropdown(index: number) {
    if (this.links && this.links[index]?.children) {
      this.links[index].isOpen = !this.links[index].isOpen;
      return;
    }

    this.openLink();
  }

  close() {
    this.visible = false;
  }
}
